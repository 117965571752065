import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import { graphql } from 'gatsby'
import ArticleList from '../components/article-list'

const Home = ({data}) => {
  return (
    <Layout>
      <Title text={`Welcome to ` + data.site.siteMetadata.title} subtitle={data.site.siteMetadata.subtitle}></Title>
      <p>Thank you for the future home of HaltonUnited.com</p>
      <p>This site is still under construction</p>
      <ArticleList />
    </Layout>
    )
}

export const query = graphql`
  query SiteMetaDataQuery {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
  }`


  export default Home
