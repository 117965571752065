import React from 'react'
import {StaticQuery, graphql } from 'gatsby'
import Article from '../components/article'

const ArticleList = () => {
  return (
    <StaticQuery
        query={ graphql`
              query {
                  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
                      totalCount
                      edges {
                        node {
                          fields {
                            slug
                          }
                          frontmatter {
                            date(formatString: "MMM YYYY")
                            title
                            image
                            keywords
                          }
                          excerpt
                        }
                      }      
                    }
              }`
            

        }
        render={ data => 
            <div>
                {data.allMarkdownRemark.edges.map(({node}) => (
                    <Article 
                        id={node.id}
                        to={node.fields.slug}
                        title={node.frontmatter.title}
                        keyword={node.frontmatter.keywords}
                        excerpt={node.excerpt}
                        date={node.frontmatter.date}
                        imageUrl={'https://source.unsplash.com/150x150?' + node.frontmatter.keywords} />
                ))}
            </div>
        }
    />
  )
}

export default ArticleList